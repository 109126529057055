/* eslint-disable */
import { Box, Modal, Paper } from "@mui/material";
import React, { useEffect } from "react";
import styles from "../ChatActions/Activity/Activity.module.scss";
import editLogo from "../../edit-name.svg";
import { useState } from "react";
import ModalComponent from "../Modals/ModalComponent/ModalComponent";
import EditNameModal from "./EditNameModal";
import CloseIcon from "@mui/icons-material/Close";
import download from "downloadjs";

const fieldNames = ["ЕГН", "Имена", "Адрес", "Допълнителна информация адрес", "Сканиран документ"];

const typeNames = ["PIN", "fullName", "address", "region", "document"];

const VerifiedModal = ({ open, handleClose, currentUser, editUserInfo, customerSupportID, getUserInfo, mobileActions, mobileModal, special, sendTemplate, editCustomerName }) => {
  const style = {
    position: "absolute",
    top: mobileActions ? "73%" : "50%",
    width: mobileModal ? "100%" : "unset",
    height: mobileModal ? "100%" : "unset",
    left: special === true ? (window.innerWidth < 1600 ? "70%" : "83%") : "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 30,
  };
  const [openEditModal, setOpenEditModal] = useState(false);
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Paper
          className={styles["activity-modal"]}
          style={{
            width: window.innerWidth < 900 && "100%",
            height: window.innerWidth < 900 && "100%",
          }}
        >
          <div
            className={styles["activity-heading-container"]}
            style={{
              justifyContent: window.innerWidth < 900 && "flex-end",
              flexDirection: window.innerWidth < 900 && "row-reverse",
              alignItems: window.innerWidth < 900 && "center",
            }}
          >
            <h3>ДАННИ ПОТРЕБИТЕЛ</h3>
            {window.innerWidth < 900 ? (
              <CloseIcon onClick={handleClose} style={{ width: "1.3em", height: "1.3em" }} />
            ) : (
              <p onClick={handleClose} style={{ cursor: "pointer" }}>
                Скрий {`>`}
              </p>
            )}
          </div>
          <div className={styles["activity-window"]}>
            {fieldNames.map((field, i) => {
              return (
                <div className={styles.activity} key={i}>
                  <p>{field}</p>
                  <h4 style={{ marginTop: "1%", cursor: i === 4 && "pointer" }}>
                    {i === 4 ? (
                      <b
                        style={{
                          textDecoration: "underline",
                          color: "#0DC1CE",
                          display: "flex",
                        }}
                        onClick={() => window.open(currentUser?.agreement?.[typeNames[i]])}
                      >
                        Лична карта <div className={styles["info-icon"]} style={{ marginLeft: "2%" }} />
                      </b>
                    ) : i === 1 ? (
                      <b style={{ display: "flex" }}>
                        {currentUser?.[typeNames[i]]}
                        <div
                          className={styles["info-icon"]}
                          style={{
                            marginLeft: "2%",
                            backgroundImage: `url(${editLogo})`,
                          }}
                          onClick={() => {
                            setOpenEditModal(true);
                          }}
                        />
                      </b>
                    ) : (
                      currentUser?.agreement?.[typeNames[i]]
                    )}
                  </h4>
                </div>
              );
            })}
            <div className={styles.activity}>
              <p>Възлагателен договор</p>
              <b
                style={{
                  textDecoration: "underline",
                  color: "#0DC1CE",
                  display: "flex",
                  cursor: "pointer",
                }}
                onClick={() => {
                  const requestOptions = {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: "Basic R290U29tZVBERkJybzpZZWFoTWF0ZUdldFRoaXNGdWNraW5nUERGIUAyIUBA",
                    },
                    body: JSON.stringify(currentUser?.getPdfRequest),
                  };
                  fetch("https://pdf.fidweb.net", requestOptions)
                    .then((res) => res.blob())
                    .then((blob) => {
                      return download(blob, "VUZLAGATELEN_DOGOVOR.pdf");
                    });
                }}
              >
                Документ
                <div className={styles["info-icon"]} style={{ marginLeft: "2%" }} />
              </b>
            </div>
          </div>
        </Paper>
        <ModalComponent
          open={openEditModal}
          handleClose={() => {
            setOpenEditModal(false);
          }}
          mobileModal={window.innerWidth < 900 && true}
          children={
            <EditNameModal
              editCustomerName={editCustomerName}
              currentUser={currentUser}
              editUserInfo={editUserInfo}
              customerSupportID={customerSupportID}
              getUserInfo={getUserInfo}
              sendTemplate={sendTemplate}
              handleClose={() => {
                setOpenEditModal(false);
              }}
            />
          }
        />
      </Box>
    </Modal>
  );
};

export default VerifiedModal;
