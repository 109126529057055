import { store } from "../config/stores";

export class Headers {
  static get() {
    return {
      "Content-Type": "application/json",
    };
  }

  static auth() {
    return {
      "Content-Type": "application/json",
      Authorization: store.getState().login.token,
    };
  }
  static basicAuth(){
    return{
      "Content-Type": "application/json",
      Authorization: "Basic " + window.btoa("customerSupport!:F1dW3bnqkv10f3r71T4M"),
    }
  }
  static basicImmotechAuth(){
    return{
      "Content-Type": "application/json",
      Authorization: "Basic " + window.btoa("#Supp0rt!:SuppPar0lka."),
    }
  }
}
