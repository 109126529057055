import S3FileUpload from "react-s3";
import { nanoid } from "nanoid";
import { AMARANT_S3_CREDENTIALS, S3_CREDENTIALS } from "../config/settings";

export const uploadFile = (file) =>
  S3FileUpload.uploadFile(
    new File([file], file?.name || nanoid(), {
      type: file?.type || "",
      lastModified: file?.lastModified || new Date(),
    }),
    S3_CREDENTIALS
  );

export const uploadFileDirName = (file, system) => {
  const s3Credentials = { ...S3_CREDENTIALS, dirName: system };

  return S3FileUpload.uploadFile(
    new File([file], file?.name || nanoid(), {
      type: file?.type || "",
      lastModified: file?.lastModified || new Date(),
    }),
    s3Credentials
  );
};

export const uploadFileAmarant = (file, info, credentials) =>
  S3FileUpload.uploadFile(
    new File([file], file?.name || nanoid(), {
      type: file?.type || "",
      lastModified: file?.lastModified || new Date(),
    }),
    credentials
  );

export const deleteFileAmarant = () =>
  S3FileUpload.deleteFile("Simeon-rangelov", {
    ...AMARANT_S3_CREDENTIALS,
    dirName: `customerSupportUploads/offer.63b6cc199f84cf0016321c5c.06.07.2023`,
  });

export const uploadFiles = (files) => {
  const promises = [];
  Array.from(files).forEach((f) =>
    promises.push(
      S3FileUpload.uploadFile(
        new File([f], f?.name || nanoid(), {
          type: f?.type,
          lastModified: f?.lastModified || new Date(),
        }),
        S3_CREDENTIALS
      )
    )
  );
  return Promise.all(promises);
};
