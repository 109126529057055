/* eslint-disable */
import { Box, Button, Divider } from "@mui/material";
import React, { useEffect, useState, useRef, useCallback } from "react";
import styles from "./AmarantComponent.module.scss";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { listVehicles } from "../../actions/amarantFields";
import { connect } from "react-redux";
import moment from "moment";
import { filterByProp } from "../../utils/helperFunctions";
import VehicleModalComponent from "./VehicleModalComponent";

const columnNames = ["Собственик", "Рег. номер", "Номер на талон", "Maрка", "Модел", "Добавен на", "Споделен", "Споделен с", "Телефон", "Споделен с", "Телефон"];

const additionalNames = ["Данни от КАТ", "Сканиран талон"];

function VehiclesComponent({ vehicles, listVehicles, customerID }) {
  const [filter, setFilter] = useState("");
  const [highestNumber, setHighestNumber] = useState(0);
  const highest = useRef(0);
  const [currentVehicle, setCurrentVehicle] = useState({});
  const [lastOpenedSection, setLastOpenedSection] = useState("");
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    listVehicles({
      //customerSupportId: "6231bc7bc8696d0016d4af13",
      customerSupportId: customerID,
    });
  }, []);

  useEffect(() => {
    vehicles.map((vehicle) => {
      if (vehicle.users.length > highest.current) highest.current = vehicle.users.length;
    });
    setHighestNumber(highest.current);
  }, [vehicles]);

  const rotateArrow = (iconId, infoId) => {
    if (document.getElementById(infoId).offsetParent !== null) {
      document.getElementById(infoId).style.display = "none";
      document.getElementById(iconId).style.transform = "rotate(360deg)";
    } else {
      if (lastOpenedSection.length > 0) {
        document.getElementById(lastOpenedSection).style.display = "none";
        document.getElementById(`icon${Number(lastOpenedSection.slice(-1)) + 10}`).style.transform = "rotate(360deg)";
      }
      document.getElementById(infoId).style.display = "block";
      document.getElementById(iconId).style.transform = "rotate(180deg)";
      setLastOpenedSection(infoId);
    }
  };

  const retrieveNames = (vehicle) => {
    const names = [];
    if (vehicle?.companyName?.length) names.push(vehicle?.companyName);
    if (vehicle?.fullName?.length) names.push(vehicle?.fullName);
    return names.join(" - ");
  };

  return (
    <div
      style={{
        width: "100%",
        height: window.innerWidth < 900 ? "120%" : "95%",
        overflowY: "auto",
      }}
    >
      {window.innerWidth < 900 ? (
        <>
          <div className={styles["tabs-container"]} style={{ padding: "0px" }}>
            {vehicles.map((vehicle, i) => {
              return (
                <div className={styles["row-container"]} style={{ marginTop: "5%", display: "block", height: "unset" }} key={i}>
                  <div className={styles["mobile-box"]}>
                    <p style={{ marginBottom: "1%", fontSize: "14px" }}>{columnNames[0]}</p>
                    <b style={{ marginBottom: "1%", fontSize: "14px" }}>{vehicle?.fullName}</b>
                  </div>
                  <div className={styles["mobile-box"]}>
                    <p style={{ marginBottom: "1%", fontSize: "14px" }}>{columnNames[1]}</p>
                    <b style={{ marginBottom: "1%", fontSize: "14px" }}>{vehicle?.licensePlate}</b>
                  </div>
                  <Divider />
                  <div className={styles["mobile-box"]}>
                    <p style={{ marginBottom: "1%", fontSize: "14px" }}>{columnNames[2]}</p>
                    <b style={{ marginBottom: "1%", fontSize: "14px" }}>{vehicle?.certificateRegistration}</b>
                  </div>
                  <Divider />
                  <div className={styles["mobile-box"]}>
                    <p style={{ marginBottom: "1%", fontSize: "14px" }}>{columnNames[3]}</p>
                    <b style={{ marginBottom: "1%", fontSize: "14px" }}>{vehicle?.carMakeEnglish.split(" ")[0]}</b>
                  </div>
                  <Divider />
                  <div className={styles["mobile-box"]}>
                    <p style={{ marginBottom: "1%", fontSize: "14px" }}>{columnNames[4]}</p>
                    <b style={{ marginBottom: "1%", fontSize: "14px" }}>{vehicle?.carMakeEnglish.includes(" ") ? vehicle?.carMakeEnglish.slice(vehicle?.carMakeEnglish.indexOf(" ") + 1) : vehicle?.carMakeEnglish}</b>
                  </div>
                  <Divider />
                  <div className={styles["mobile-box"]}>
                    <p style={{ marginBottom: "1%", fontSize: "14px" }}>{columnNames[5]}</p>
                    <b style={{ marginBottom: "1%", fontSize: "14px" }}>{moment(vehicle?.createdAt).format("DD.MM.YYYY | HH:mm")}</b>
                  </div>
                  <Divider />
                  <div className={styles["mobile-box"]}>
                    <p style={{ marginBottom: "1%", fontSize: "14px" }}>{columnNames[6]}</p>
                    <b style={{ marginBottom: "1%", fontSize: "14px" }}>{vehicle.users.length > 0 ? "Да" : "Не"}</b>
                  </div>
                  <Divider />
                  <div className={styles["mobile-box"]}>
                    <p style={{ marginBottom: "1%", fontSize: "14px" }}>Данни от КАТ</p>
                    <b style={{ marginBottom: "1%", fontSize: "14px" }}>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          marginTop: "1%",
                        }}
                      >
                        <b
                          style={{
                            textDecoration: "underline",
                            color: "#0DC1CE",
                            cursor: "pointer",
                            fontSize: "15px",
                          }}
                          onClick={() => {
                            setOpenModal(true);
                            setCurrentVehicle(vehicle);
                          }}
                        >
                          Преглед
                        </b>
                        <div
                          className={styles["info-icon"]}
                          style={{ marginLeft: "2%", marginRight: "5%" }}
                          onClick={() => {
                            setOpenModal(true);
                            setCurrentVehicle(vehicle);
                          }}
                        />
                      </div>
                    </b>
                  </div>
                  <Divider />
                  <div className={styles["mobile-box"]}>
                    <p style={{ marginBottom: "1%", fontSize: "14px" }}>Сканиран талон</p>
                    {vehicle?.pictures?.length > 0 ? (
                      <div
                        className={styles["data"]}
                        style={{
                          cursor: "pointer",
                          width: "100%",
                          alignItems: "center",
                          marginLeft: "0%",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            marginTop: "1%",
                          }}
                        >
                          <b
                            style={{
                              textDecoration: "underline",
                              color: "#0DC1CE",
                              cursor: "pointer",
                              fontSize: "15px",
                            }}
                            onClick={() => {
                              window.open(vehicle.pictures[0]);
                            }}
                          >
                            Преглед
                          </b>
                          <div
                            className={styles["info-icon"]}
                            style={{ marginLeft: "2%", marginRight: "5%" }}
                            onClick={() => {
                              window.open(vehicle.pictures[0]);
                            }}
                          />
                        </div>

                        {vehicle?.pictures?.length > 1 && (
                          <div style={{ display: "flex", width: "100%" }}>
                            <b
                              style={{
                                textDecoration: "underline",
                                color: "#0DC1CE",
                                cursor: "pointer",
                                fontSize: "15px",
                              }}
                              onClick={() => {
                                window.open(vehicle.pictures[1]);
                              }}
                            >
                              Преглед
                            </b>
                            <div
                              className={styles["info-icon"]}
                              style={{ marginLeft: "2%", marginRight: "5%" }}
                              onClick={() => {
                                window.open(vehicle.pictures[1]);
                              }}
                            />
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className={styles["data"]}>---</div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </>
      ) : (
        <>
          <div className={styles["columns-container"]}>
            <div
              className={styles["upper-names"]}
              style={{
                width: window.innerWidth < 1400 ? "150%" : "100%",
              }}
            >
              {" "}
              <div className={`${styles["name-icon"]}`}>
                <p>{columnNames[0]}</p>
                <KeyboardArrowDownIcon
                  style={{
                    transform: filter === "plate" && "rotate(180deg)",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (filter === "plate") {
                      setFilter("reversePlate");
                      filterByProp(vehicles, "reversePlate", "licensePlate");
                    } else {
                      setFilter("plate");
                      filterByProp(vehicles, "plate", "licensePlate");
                    }
                  }}
                  id={1}
                />
              </div>
              <div className={`${styles["name-icon"]}`}>
                <p>{columnNames[1]}</p>
                <KeyboardArrowDownIcon
                  style={{
                    transform: filter === "plate" && "rotate(180deg)",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (filter === "plate") {
                      setFilter("reversePlate");
                      filterByProp(vehicles, "reversePlate", "licensePlate");
                    } else {
                      setFilter("plate");
                      filterByProp(vehicles, "plate", "licensePlate");
                    }
                  }}
                  id={1}
                />
              </div>
              <div className={`${styles["name-icon"]}`}>
                <p>{columnNames[2]}</p>
                <KeyboardArrowDownIcon
                  style={{
                    transform: filter === "reg" && "rotate(180deg)",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (filter === "reg") {
                      setFilter("reverseReg");
                      filterByProp(vehicles, "reversePlate", "certificateRegistration");
                    } else {
                      setFilter("reg");
                      filterByProp(vehicles, "plate", "certificateRegistration");
                    }
                  }}
                  id={2}
                />
              </div>
              <div className={`${styles["name-icon"]}`}>
                <p>{columnNames[3]}</p>
                <KeyboardArrowDownIcon
                  style={{
                    transform: filter === "name" && "rotate(180deg)",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (filter === "name") {
                      setFilter("reverseName");
                      filterByProp(vehicles, "reversePlate", "carMakeEnglish");
                    } else {
                      setFilter("name");
                      filterByProp(vehicles, "plate", "carMakeEnglish");
                    }
                  }}
                  id={3}
                />
              </div>
              <div className={`${styles["name-icon"]}`} style={{ width: "6%" }}>
                <p>{columnNames[4]}</p>
                <KeyboardArrowDownIcon
                  style={{
                    transform: filter === "model" && "rotate(180deg)",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (filter === "model") {
                      setFilter("reverseModel");
                      filterByProp(vehicles, "reversePlate", "carMakeEnglish");
                    } else {
                      setFilter("model");
                      filterByProp(vehicles, "plate", "carMakeEnglish");
                    }
                  }}
                  id={4}
                />
              </div>
              <div className={`${styles["name-icon"]}`}>
                <p>{columnNames[5]}</p>
                <KeyboardArrowDownIcon
                  style={{
                    transform: filter === "createdAt" && "rotate(180deg)",
                    cursor: "pointer",
                  }}
                  className={styles["sortArrow"]}
                  onClick={() => {
                    if (filter === "createdAt") {
                      setFilter("reverseCreatedAt");
                      filterByProp(vehicles, "reverseCreatedAt", "createdAt");
                    } else {
                      setFilter("createdAt");
                      filterByProp(vehicles, "createdAt", "createdAt");
                    }
                  }}
                  id={5}
                />
              </div>
              <div className={`${styles["name-icon"]}`}>
                <p>{columnNames[6]}</p>
                <KeyboardArrowDownIcon
                  style={{
                    transform: filter === "plate" && "rotate(180deg)",
                    cursor: "pointer",
                  }}
                  className={styles["sortArrow"]}
                  id={6}
                />
              </div>
              <div className={`${styles["name-icon"]}`}>
                <p>{additionalNames[0]}</p>
              </div>
              <div className={`${styles["name-icon"]}`}>
                <p>{additionalNames[1]}</p>
              </div>
            </div>
          </div>
          <div className={styles["tabs-container"]}>
            {vehicles.map((vehicle, i) => {
              return (
                <React.Fragment key={i}>
                  <div
                    className={styles["row-container"]}
                    style={{
                      width: window.innerWidth < 1400 ? "150%" : "100%",
                    }}
                  >
                    <div className={styles["data"]}>
                      <b>{retrieveNames(vehicle)}</b>
                    </div>
                    <Divider
                      style={{
                        backgroundColor: "#D3D3D3",
                        height: "65%",
                      }}
                      orientation="vertical"
                    />
                    <div className={styles["data"]}>
                      <b>{vehicle?.licensePlate}</b>
                    </div>
                    <Divider
                      style={{
                        backgroundColor: "#D3D3D3",
                        height: "65%",
                      }}
                      orientation="vertical"
                    />
                    <div className={styles["data"]}>
                      <b>{vehicle?.certificateRegistration}</b>
                    </div>
                    <Divider
                      style={{
                        backgroundColor: "#D3D3D3",
                        height: "65%",
                      }}
                      orientation="vertical"
                    />
                    <div className={styles["data"]}>
                      <b>{vehicle?.carMakeEnglish.split(" ")[0]}</b>
                    </div>
                    <Divider
                      style={{
                        backgroundColor: "#D3D3D3",
                        height: "65%",
                      }}
                      orientation="vertical"
                    />
                    <div className={styles["data"]} style={{ whiteSpace: "normal" }}>
                      <b>{vehicle?.carMakeEnglish.includes(" ") ? vehicle?.carMakeEnglish.slice(vehicle?.carMakeEnglish.indexOf(" ") + 1) : vehicle?.carMakeEnglish}</b>
                    </div>
                    <Divider
                      style={{
                        backgroundColor: "#D3D3D3",
                        height: "65%",
                      }}
                      orientation="vertical"
                    />
                    <div className={styles["data"]}>
                      <b>{moment(vehicle?.createdAt).format("DD.MM.YYYY | HH:mm")}</b>
                    </div>
                    <Divider
                      style={{
                        backgroundColor: "#D3D3D3",
                        height: "65%",
                      }}
                      orientation="vertical"
                    />
                    <div className={styles["data"]}>
                      <b>{vehicle.users.length > 0 ? "Да" : "Не"}</b>
                      {vehicle.users.length > 0 && (
                        <div
                          className={styles["arrowDown"]}
                          id={`icon${i + 10}`}
                          onClick={() => {
                            setCurrentVehicle(vehicle);
                            rotateArrow(`icon${i + 10}`, `info${i}`);
                          }}
                        />
                      )}
                    </div>
                    <Divider
                      style={{
                        backgroundColor: "#D3D3D3",
                        height: "65%",
                      }}
                      orientation="vertical"
                    />
                    <div
                      className={styles["data"]}
                      onClick={() => {
                        setOpenModal(true);
                        setCurrentVehicle(vehicle);
                      }}
                    >
                      <b
                        style={{
                          textDecoration: "underline",
                          color: "#0DC1CE",
                          fontWeight: "bold",
                          cursor: "pointer",
                        }}
                      >
                        Преглед
                      </b>
                      <div className={styles["info-icon"]} />
                    </div>
                    <Divider
                      style={{
                        backgroundColor: "#D3D3D3",
                        height: "65%",
                      }}
                      orientation="vertical"
                    />
                    {vehicle?.pictures?.length > 0 ? (
                      <div className={styles["data"]} style={{ cursor: "pointer", width: "17%" }}>
                        <b
                          style={{
                            textDecoration: "underline",
                            color: "#0DC1CE",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            window.open(vehicle.pictures[0]);
                          }}
                        >
                          Преглед
                        </b>
                        <div
                          className={styles["info-icon"]}
                          style={{ marginLeft: "2%", marginRight: "5%" }}
                          onClick={() => {
                            window.open(vehicle.pictures[0]);
                          }}
                        />
                        {vehicle?.pictures?.length > 1 && (
                          <>
                            <b
                              style={{
                                textDecoration: "underline",
                                color: "#0DC1CE",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                window.open(vehicle.pictures[1]);
                              }}
                            >
                              Преглед
                            </b>
                            <div
                              className={styles["info-icon"]}
                              style={{ marginLeft: "2%", marginRight: "5%" }}
                              onClick={() => {
                                window.open(vehicle.pictures[1]);
                              }}
                            />
                          </>
                        )}
                      </div>
                    ) : (
                      <div className={styles["data"]}>---</div>
                    )}
                  </div>
                  <div className={`${styles["info-inner-tab"]}`} id={`info${i}`}>
                    <div className={styles["columns-container"]} style={{ marginTop: "-0.5%" }}>
                      <div className={styles["upper-names"]}>
                        <div className={`${styles["name-icon"]}`} style={{ width: "12%" }}>
                          <p>Споделен с</p>
                          <KeyboardArrowDownIcon
                            style={{
                              transform: filter === "fullName" && "rotate(180deg)",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              if (filter === "fullName") {
                                setFilter("reverseFullName");
                                filterByProp(currentVehicle?.users, "reversePlate", "fullName");
                              } else {
                                setFilter("fullName");
                                filterByProp(currentVehicle?.users, "plate", "fullName");
                              }
                            }}
                          />
                        </div>
                        <div className={`${styles["name-icon"]}`} style={{ width: "12%" }}>
                          <p>Телефон</p>
                          <KeyboardArrowDownIcon
                            style={{
                              transform: filter === "phoneNumber" && "rotate(180deg)",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              if (filter === "phoneNumber") {
                                setFilter("reversePhoneNumber");
                                filterByProp(currentVehicle?.users, "reversePlate", "phoneNumber");
                              } else {
                                setFilter("phoneNumber");
                                filterByProp(currentVehicle?.users, "plate", "phoneNumber");
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={styles["tabs-container"]}>
                      {currentVehicle?.users?.map((user, i) => {
                        return (
                          <div className={styles["row-container"]} style={{ height: "60px" }} key={i}>
                            <div className={styles["data"]} style={{ width: "12%" }}>
                              <b>{user?.fullName}</b>
                            </div>
                            <Divider
                              style={{
                                backgroundColor: "#D3D3D3",
                                height: "65%",
                              }}
                              orientation="vertical"
                            />
                            <div className={styles["data"]} style={{ width: "12%" }}>
                              <b>{user?.phoneNumber}</b>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
          </div>
        </>
      )}

      <VehicleModalComponent open={openModal} handleClose={() => setOpenModal(false)} currentVehicle={currentVehicle} mobileModal={window.innerWidth < 900 && true} />
    </div>
  );
}

const mapStateToProps = (state) => ({
  vehicles: state.amarantFields.vehicles,
});

const mapDispatchToProps = (dispatch) => ({
  listVehicles: (payload) => dispatch(listVehicles(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VehiclesComponent);
