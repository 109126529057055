/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import "./styles.scss";
import { forwardRef } from "react";
import { uploadFile, uploadFileAmarant } from "../../../utils/files";
import { AMARANT_S3_CREDENTIALS } from "../../../config/settings";

const ImageInput = ({
  label,
  compulsory,
  style,
  inputStyle,
  className,
  onChange,
  setValue,
  innerWidth,
  files,
  key,
  disabled,
  specialDir,
  info,
  ...props
}) => {
  const uploadRef = useRef();
  const handleUpload = async ({ target: { files } }) => {
    if (specialDir) {
      uploadFileAmarant(files[0], info, {
        ...AMARANT_S3_CREDENTIALS,
        dirName: `customerSupportUploads/${info?.keyword}.${info?.id}.${info?.date}`,
      }).then((data) => {
        if (onChange) onChange(data.location);
      });
    } else
      uploadFile(files[0]).then((data) => {
        if (onChange) {
          onChange(data.location);
        }
      });
  };

  return (
    <div className="input-container" style={style}>
      {label && (
        <label style={{ display: "flex", width: "100%", fontSize: "0.8rem" }}>
          {label}{" "}
          {compulsory && (
            <span style={{ paddingLeft: "5px", color: "black" }}>*</span>
          )}
        </label>
      )}

      <div
        className={`prefix-input-container-image ${
          !props.value && "dotted"
        } ${className}`}
        style={{
          width: innerWidth ? "98%" : "50%",
          justifyContent: "space-between",
          cursor: "pointer",
        }}
        onClick={() => {
          if (uploadRef) {
            if (!props.value) uploadRef.current.click();
          }
        }}
      >
        {!props.value ? (
          <>
            {" "}
            <h3 style={{ marginLeft: "4%" }}>UPLOAD</h3>
            <input
              ref={uploadRef}
              style={{ display: "none" }}
              disabled={disabled}
              type="file"
              onChange={handleUpload}
            />
          </>
        ) : (
          <>
            <h4 style={{ marginLeft: "4%" }}>
              {specialDir ? props.value.split("/")[5].slice(0, 25) : props.value.split("/")[3].slice(0, 20)}
            </h4>
            <div
              className="close-icon"
              style={{ marginRight: "4%" }}
              onClick={() => {
                onChange("");
              }}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ImageInput;
