/* eslint-disable */
import React, { useEffect, useState } from "react";
import styles from "./Offer.module.scss";
import Inputs from "../../Inputs";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { connect } from "react-redux";
import { listAgencies, createOffer } from "../../../actions/offer";
import { getCurrentTicket } from "../../../actions/tickets";
import moment from "moment";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import "./Offer.module.scss";
import _ from "lodash";
import DiscountInfoModal from "../../Modals/DiscountInfoModal/DiscountInfoModal";
import ModalComponent from "../../Modals/ModalComponent/ModalComponent";
import { calculateMonetary } from "../../../utils/helperFunctions";

const customStyles = {
  dropdownIndicator: (base, state) => ({
    ...base,
    color: "#0083E5", // Custom colour
    transition: "all .2s ease",
    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
  }),
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px dotted pink",
    color: state.isSelected ? "white" : "black",
    backgroundColor: state.isSelected ? "#0083E5" : "white",
    padding: 10,
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    width: (window.innerWidth / 100) * 18,
    height: 40,
    display: "flex",
    border: "1px solid rgb(165, 164, 164)",
    borderRadius: "7px",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
};

const customStylesHigher = {
  dropdownIndicator: (base, state) => ({
    ...base,
    color: "#0083E5", // Custom colour
    transition: "all .2s ease",
    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
  }),
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px dotted pink",
    color: state.isSelected ? "white" : "black",
    backgroundColor: state.isSelected ? "#0083E5" : "white",
    padding: 10,
  }),
  valueContainer: (base) => ({
    ...base,
    overflowY: "scroll",
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    width: (window.innerWidth / 100) * 21,
    display: "flex",
    height: 70,
    border: "1px solid rgb(165, 164, 164)",
    borderRadius: "7px",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
};

const instalPairs = [
  {
    key: "installmentOneDate",
    label: "Първа вноска падеж",
    sumKey: "installmentOneSum",
  },
  {
    key: "installmentTwoDate",
    label: "Втора вноска падеж",
    sumKey: "installmentTwoSum",
  },
  {
    key: "installmentThreeDate",
    label: "Трета вноска падеж",
    sumKey: "installmentThreeSum",
  },
  {
    key: "installmentFourDate",
    label: "Четвърта вноска падеж",
    sumKey: "installmentFourSum",
  },
];

const liquidationOptions = ["Официален сервиз", "Доверен сервиз", "Експертна оценка"];
const viewOptions = ["Да", "Не"];
const additionalSecurityOptions = ["Да", "Не"];
const premiumInfoOptions = [
  "Всички суми са в лева с включен данък.",
  "Застрахователните премии са крайни, с включени отстъпки.",
  "В качеството си на 'Специален потребител', на мобилното приложение Амарант в офертата е калкулирана и допълнителна преференциална отстъпка за Вас",
  "Застрахователните премии са валидни до промяна в тарифата на застрахователя или рисковите обстоятелства",
];

const CreateOffer = ({ listAgencies, agencies, createOffer, getCurrentTicket, currentTicket }) => {
  const [instCount, setInstCount] = useState(1);
  const ticketID = window.location.href.split("/")[4];
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();

  const {
    register,
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    startDate: "",
    installmentsCount: 1,
    installmentOneDate: "",
    installmentTwoDate: null,
    installmentThreeDate: null,
    installmentFourDate: null,
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "offers",
  });

  useEffect(() => {
    listAgencies();
    getCurrentTicket({
      id: ticketID,
      onSuccess: (res) => {
        const type = res?.promoData?.policyType;
        if (res?.promoData?.discountCampaign?.discount)
          //general for all types
          setValue(`offers.${0}.fullDiscount`, {
            type: type,
            discount: res?.promoData?.discountCampaign?.discount,
            percent: res?.promoData?.discountCampaign?.discountInPercent,
            byCompany: null,
          });
        else if (res?.promoData?.discountCampaign?.customDiscounts?.filter((disc) => disc?.policyType === type)[0].discount)
          //general for all companies
          setValue(`offers.${0}.fullDiscount`, {
            type: type,
            discount: res?.promoData?.discountCampaign?.customDiscounts?.filter((disc) => disc?.policyType === type)[0].discount,
            percent: res?.promoData?.discountCampaign?.discountInPercent,
            byCompany: null,
          });
        // different for every company
        else {
          setValue(`offers.${0}.fullDiscount`, {
            type: type,
            discount: null,
            byCompany: res?.promoData?.discountCampaign?.customDiscounts?.filter((disc) => disc?.policyType === type)?.[0]?.customDiscountsPerInsuranceAgency,
            percent: res?.promoData?.discountCampaign?.discountInPercent,
          });
        }
      },
    });
    setValue("installmentsCount", 1);
    append({
      insuranceCompanyName: "",
      insuranceCompanyId: "",
      insuranceSum: null,
      premium: null,
      originalPremium: null,
      installmentOneSum: null,
      installmentTwoSum: null,
      installmentThreeSum: null,
      installmentFourSum: null,
      documents: "",
      generalConditions: "",
      additionalInfo: "",
      liquidation: "",
      view: "",
      security: "",
      premiumInfo: [],
    });
  }, []);

  const calculatePercentDiscount = (premium, discount) => {
    if (discount > 0 && premium) {
      const formula = premium - ((premium / 1.02) * discount) / 100;
      // const VAT = 0.02;
      // const discountMultiplier = 1 - discount / 100;
      // const discountableAmount = calculateMonetary(e - e * VAT);
      // const discountedPremium = calculateMonetary(discountableAmount * discountMultiplier);
      return calculateMonetary(formula);
    } else return premium;
  };

  const handleOfferWithoutDiscount = (e) => {
    const payload = {
      mainParams: {
        ticket: ticketID,
        startDate: moment(e.startDate).format("YYYY-MM-DD"),
        installmentsCount: e.installmentsCount,
        installmentOneDate: moment(e.installmentOneDate).format("YYYY-MM-DD"),
        installmentTwoDate: e.installmentTwoDate ? moment(e.installmentTwoDate).format("YYYY-MM-DD") : undefined,
        installmentThreeDate: e.installmentThreeDate ? moment(e.installmentThreeDate).format("YYYY-MM-DD") : undefined,
        installmentFourDate: e.installmentFourDate ? moment(e.installmentFourDate).format("YYYY-MM-DD") : undefined,
      },
      offers: e.offers.map((offer) => {
        return {
          ...offer,
          documents: [offer.documents],
          generalConditions: [offer.generalConditions],
          installmentOneSum: Number(offer.installmentOneSum),
          installmentTwoSum: Number(offer.installmentTwoSum),
          installmentThreeSum: Number(offer.installmentThreeSum),
          installmentFourSum: Number(offer.installmentFourSum),
          liquidation: offer?.liquidation,
          view: offer?.view,
          security: offer?.security,
          premiumInfo: offer?.premiumInfo.map((info) => info.value),
          premium: offer?.premium,
        };
      }),
      onSuccess: (res) => {
        toast.success("Offer created successfully");
        navigate(-1);
      },
    };

    createOffer(payload);
  };

  const handleOfferWithDiscount = (e) => {
    const payload = {
      mainParams: {
        ticket: ticketID,
        startDate: moment(e.startDate).format("YYYY-MM-DD"),
        installmentsCount: e.installmentsCount,
        installmentOneDate: moment(e.installmentOneDate).format("YYYY-MM-DD"),
        installmentTwoDate: e.installmentTwoDate ? moment(e.installmentTwoDate).format("YYYY-MM-DD") : undefined,
        installmentThreeDate: e.installmentThreeDate ? moment(e.installmentThreeDate).format("YYYY-MM-DD") : undefined,
        installmentFourDate: e.installmentFourDate ? moment(e.installmentFourDate).format("YYYY-MM-DD") : undefined,
      },
      offers: e.offers.map((offer) => {
        const discountedAmount = offer?.fullDiscount?.percent ? calculatePercentDiscount(offer?.originalPremium, offer?.fullDiscount.discount) : offer?.originalPremium - offer?.fullDiscount.discount;

        return {
          ...offer,
          documents: [offer.documents],
          generalConditions: [offer.generalConditions],
          installmentOneSum: +(discountedAmount / instCount).toFixed(2),
          installmentTwoSum: instCount >= 2 ? +(discountedAmount / instCount).toFixed(2) : Number(offer.installmentTwoSum),
          installmentThreeSum: instCount >= 3 ? +(discountedAmount / instCount).toFixed(2) : Number(offer.installmentThreeSum),
          installmentFourSum: instCount === 4 ? +(discountedAmount / instCount).toFixed(2) : Number(offer.installmentFourSum),
          liquidation: offer?.liquidation,
          view: offer?.view,
          security: offer?.security,
          premiumInfo: offer?.premiumInfo?.map((info) => info.value),
          premium: offer?.originalPremium ? Number(discountedAmount) : offer?.premium,
          discountInfo: offer?.fullDiscount?.percent ? `-${offer?.fullDiscount.discount}%` : `-${offer?.fullDiscount.discount}лв.`,
        };
      }),
      onSuccess: (res) => {
        toast.success("Offer created successfully");
        navigate(-1);
      },
    };
    payload.offers.map((offer, i) => {
      const installmentsSum = offer?.installmentOneSum + offer?.installmentTwoSum + offer?.installmentThreeSum + offer?.installmentFourSum;
      if (installmentsSum < offer?.premium) {
        const newOffers = payload.offers.slice(0);
        newOffers[i].installmentOneSum += offer?.premium - installmentsSum;
      }
    });

    createOffer(payload);
  };

  return (
    <div className={styles["amarant-container"]}>
      <div className={styles["upper-info-container"]}>
        <div style={{ display: "flex", width: "40%", alignItems: "center", marginLeft: "1%" }}>
          <h2 className={styles["title"]}>Създаване на оферти</h2>
          {currentTicket?.promoData && <div className={styles["info-icon"]} onClick={() => setOpenModal(true)} />}
        </div>
        <Inputs.Button
          text="Запази"
          className="green"
          style={{ marginRight: "1%", width: "130px" }}
          onClick={handleSubmit((e) => {
            if (!currentTicket?.promoData) handleOfferWithoutDiscount(e);
            else handleOfferWithDiscount(e);
          })}
        />
      </div>
      <div className={styles["middle-info-container"]}>
        <h2 className={styles["title"]}>Основни параметри</h2>
        <div className={styles["main-parameters-container"]}>
          <Controller
            control={control}
            name={"startDate"}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Inputs.DatePicker
                label={"Начална дата"}
                compulsory
                custom
                style={{ width: "12%", height: "100%" }}
                value={value}
                onChange={(e) => {
                  setValue("startDate", e);
                }}
                className={error && "failed"}
              />
            )}
            rules={{ required: true }}
          />
          <Controller
            control={control}
            name={"installmentsCount"}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <div style={{ margin: "0% 3%" }}>
                <label>Брой вноски</label>
                <div style={{ display: "flex" }}>
                  <Inputs.Button
                    text={1}
                    selected={value === 1}
                    style={{ marginRight: "3%" }}
                    onClick={() => {
                      setValue("installmentsCount", 1);
                      setInstCount(1);
                    }}
                  />
                  <Inputs.Button
                    text={2}
                    selected={value === 2}
                    style={{ marginRight: "3%" }}
                    onClick={() => {
                      setValue("installmentsCount", 2);
                      setInstCount(2);
                    }}
                  />
                  <Inputs.Button
                    text={3}
                    selected={value === 3}
                    style={{ marginRight: "3%" }}
                    onClick={() => {
                      setValue("installmentsCount", 3);
                      setInstCount(3);
                    }}
                  />
                  <Inputs.Button
                    text={4}
                    selected={value === 4}
                    style={{ marginRight: "3%" }}
                    onClick={() => {
                      setValue("installmentsCount", 4);
                      setInstCount(4);
                    }}
                  />
                </div>
              </div>
            )}
            rules={{ required: true }}
          />
          {Array(instCount)
            .fill("")
            .map((el, i) => {
              return (
                <Controller
                  control={control}
                  key={i}
                  name={instalPairs[i].key}
                  render={({ field: { value, onChange }, fieldState: { error } }) => (
                    <Inputs.DatePicker
                      label={instalPairs[i].label}
                      compulsory
                      custom
                      style={{ width: "12%", height: "100%", margin: "0% 2%" }}
                      value={value}
                      onChange={(e) => {
                        setValue(instalPairs[i].key, e);
                      }}
                      className={error && "failed"}
                    />
                  )}
                  rules={{ required: true }}
                />
              );
            })}
          <div className="input-container" style={{ width: "5%" }}>
            <label style={{ display: "flex", width: "100%", fontSize: "0.8rem", marginBottom: "5px" }}>Отстъпка</label>
            <p style={{ marginTop: "10px", color: currentTicket?.promoData && "#01D088" }}>{currentTicket?.promoData ? "Да" : "Не"}</p>
          </div>
        </div>
      </div>
      {fields?.map((offer, i) => {
        return (
          <div className={styles["lower-info-container"]} key={i}>
            <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
              <h2 className={styles["title"]}>Оферта {i + 1}</h2>
              {fields?.length > 1 && (
                <div
                  className={styles["delete-icon"]}
                  onClick={() => {
                    remove(i);
                  }}
                />
              )}
            </div>
            <div>
              <div className={styles["main-parameters-container"]}>
                <Controller
                  control={control}
                  name={`offers.${i}.insuranceCompanyName`}
                  render={({ field: { value, onChange }, fieldState: { error } }) => (
                    <Inputs.Dropdown
                      label={"Застрахователна компания"}
                      optionsArray={agencies?.map((agency) => {
                        return {
                          label: agency?.name,
                          value: agency?._id,
                        };
                      })}
                      key={offer.id}
                      compulsory
                      customStyles={customStyles}
                      style={{ width: "20%", height: "100%" }}
                      single
                      value={value}
                      className={error && "failed"}
                      onChange={(e) => {
                        setValue(`offers.${i}.insuranceCompanyName`, e.label);
                        setValue(`offers.${i}.insuranceCompanyId`, e.value);
                        if (watch()?.offers?.[i]?.fullDiscount?.byCompany) setValue(`offers.${i}.fullDiscount.discount`, watch()?.offers?.[i]?.fullDiscount?.byCompany?.filter((comp) => comp?.insuranceAgency === e.value)?.length ? watch()?.offers?.[i]?.fullDiscount.byCompany?.filter((comp) => comp?.insuranceAgency === e.value)?.[0]?.discount : 0);
                      }}
                    />
                  )}
                  rules={{ required: true }}
                />

                <Inputs.TextInput label={"Застрахователна сума"} suffix={"лв."} style={{ width: "9%", marginRight: "1%" }} className={errors.offers?.[i]?.insuranceSum ? "failed" : ""} {...register(`offers.${i}.insuranceSum`)} />
                {!currentTicket?.promoData ? (
                  <Controller
                    control={control}
                    name={`offers.${i}.premium`}
                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                      <Inputs.TextInput
                        label={"Премия"}
                        compulsory
                        suffix={"лв."}
                        type="number"
                        style={{ width: "9%", marginRight: "1%" }}
                        className={errors.offers?.[i]?.premium ? "failed" : ""}
                        onChange={(e) => {
                          setValue(`offers.${i}.premium`, Number(e.target.value));
                        }}
                      />
                    )}
                    rules={{ required: true }}
                  />
                ) : (
                  <>
                    <Controller
                      control={control}
                      name={`offers.${i}.originalPremium`}
                      render={({ field: { value, onChange }, fieldState: { error } }) => (
                        <>
                          <Inputs.TextInput
                            label={"Премия"}
                            compulsory
                            suffix={"лв."}
                            type="number"
                            style={{ width: "9%", marginRight: "1%" }}
                            className={errors.offers?.[i]?.originalPremium ? "failed" : ""}
                            onChange={(e) => {
                              setValue(`offers.${i}.originalPremium`, Number(e.target.value));
                            }}
                          />
                          <Inputs.TextInput
                            label={"Премия с отстъпка"}
                            suffix={"лв."}
                            value={watch()?.offers?.[i]?.fullDiscount?.percent ? calculatePercentDiscount(value, watch()?.offers?.[i]?.fullDiscount?.discount)?.toFixed(2) : (value - watch()?.offers?.[i]?.fullDiscount?.discount).toFixed(2)}
                            disabled
                            style={{ width: "9%", marginRight: "1%" }}
                          />
                          {Array(instCount)
                            .fill("")
                            .map((el, j) => {
                              return (
                                <>
                                  <Inputs.TextInput
                                    label={`Вноска ${j + 1}`}
                                    compulsory
                                    disabled={true}
                                    key={j + 1}
                                    value={watch()?.offers?.[i]?.fullDiscount?.percent ? +(calculatePercentDiscount(value, watch()?.offers?.[i]?.fullDiscount?.discount) / instCount).toFixed(2) : +((value - watch()?.offers?.[i]?.fullDiscount?.discount) / instCount).toFixed(2)}
                                    suffix={"лв."}
                                    style={{ width: "9%", marginRight: "1%" }}
                                  />
                                </>
                              );
                            })}
                        </>
                      )}
                      rules={{ required: true }}
                    />
                  </>
                )}

                {!currentTicket?.promoData &&
                  Array(instCount)
                    .fill("")
                    .map((el, j) => {
                      return (
                        <Inputs.TextInput
                          label={`Вноска ${j + 1}`}
                          compulsory
                          key={j + 1}
                          suffix={"лв."}
                          style={{ width: "9%", marginRight: "1%" }}
                          className={errors.offers?.[i]?.[instalPairs[j].sumKey] ? "failed" : ""}
                          {...register(`offers.${i}.${instalPairs[j].sumKey}`, {
                            required: true,
                          })}
                        />
                      );
                    })}
                {currentTicket?.promoData && (
                  <div className="input-container" style={{ width: "5%" }}>
                    <label style={{ display: "flex", width: "100%", fontSize: "0.8rem", marginBottom: "5px" }}>Отстъпка</label>
                    {watch()?.offers?.[i]?.fullDiscount?.discount ? <p style={{ marginTop: "10px" }}>{watch()?.offers?.[i]?.fullDiscount?.percent ? `${watch()?.offers?.[i]?.fullDiscount?.discount}%` : `${watch()?.offers?.[i]?.fullDiscount?.discount}лв.`}</p> : "---"}
                  </div>
                )}
              </div>
              <div className={styles["main-parameters-container-files"]}>
                <Controller
                  control={control}
                  name={`offers.${i}.documents`}
                  render={({ field: { value, onChange }, fieldState: { error } }) => (
                    <>
                      <Inputs.ImageInput
                        label={"Информационен документ"}
                        compulsory
                        style={{ width: "25%", margin: "10px 0px" }}
                        value={value}
                        specialDir={true}
                        info={{
                          keyword: "offer",
                          id: ticketID,
                          date: moment().format("DD.MM.YYYY"),
                        }}
                        key={"documents"}
                        onChange={(e) => {
                          setValue(`offers.${i}.documents`, e);
                        }}
                        setValue={setValue}
                        className={error && "failed"}
                      />
                    </>
                  )}
                  rules={{ required: true }}
                />
                <Controller
                  control={control}
                  name={`offers.${i}.generalConditions`}
                  render={({ field: { value, onChange }, fieldState: { error } }) => (
                    <Inputs.ImageInput
                      label={"Общи условия"}
                      compulsory
                      style={{ width: "25%", margin: "10px 0px" }}
                      value={value}
                      specialDir={true}
                      info={{
                        keyword: "offer",
                        id: ticketID,
                        date: moment().format("DD.MM.YYYY"),
                      }}
                      onChange={(e) => {
                        setValue(`offers.${i}.generalConditions`, e);
                      }}
                      setValue={setValue}
                      className={error && "failed"}
                    />
                  )}
                  rules={{ required: true }}
                />
              </div>
              <div className={styles["main-parameters-container"]}>
                <Controller
                  control={control}
                  name={`offers.${i}.liquidation`}
                  render={({ field: { value, onChange }, fieldState: { error } }) => (
                    <Inputs.Dropdown
                      label={"Тип ликвидация"}
                      optionsArray={liquidationOptions?.map((liq) => {
                        return {
                          label: liq,
                          value: liq,
                        };
                      })}
                      customStyles={customStyles}
                      style={{ width: "23%", height: "100%" }}
                      single
                      value={value}
                      className={error && "failed"}
                      onChange={(e) => {
                        setValue(`offers.${i}.liquidation`, e.value);
                      }}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name={`offers.${i}.view`}
                  render={({ field: { value, onChange }, fieldState: { error } }) => (
                    <Inputs.Dropdown
                      label={"Изискване за оглед"}
                      optionsArray={viewOptions?.map((view) => {
                        return {
                          label: view,
                          value: view,
                        };
                      })}
                      customStyles={customStyles}
                      style={{ width: "23%", height: "100%" }}
                      single
                      value={value}
                      onChange={(e) => {
                        setValue(`offers.${i}.view`, e.value);
                      }}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name={`offers.${i}.security`}
                  render={({ field: { value, onChange }, fieldState: { error } }) => (
                    <Inputs.Dropdown
                      label={"Mонтиране на допълнително средство за защита"}
                      optionsArray={additionalSecurityOptions?.map((opt) => {
                        return {
                          label: opt,
                          value: opt,
                        };
                      })}
                      customStyles={customStyles}
                      style={{ width: "23%", height: "100%" }}
                      single
                      value={value}
                      onChange={(e) => {
                        setValue(`offers.${i}.security`, e.value);
                      }}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name={`offers.${i}.premiumInfo`}
                  render={({ field: { value, onChange }, fieldState: { error } }) => (
                    <Inputs.Dropdown
                      label={"Премия"}
                      optionsArray={premiumInfoOptions?.map((opt) => {
                        return {
                          label: opt,
                          value: opt,
                        };
                      })}
                      customStyles={customStylesHigher}
                      style={{ width: "23%", height: "100%" }}
                      value={value}
                      onChange={(e) => {
                        setValue(`offers.${i}.premiumInfo`, e);
                      }}
                    />
                  )}
                />
              </div>
              <Inputs.TextInput
                label={"Допълнителна информация за офертата"}
                compulsory
                style={{ width: "100%", margin: "1% 0%" }}
                className={errors.offers?.[i]?.additionalInfo ? "failed" : ""}
                {...register(`offers.${i}.additionalInfo`, {
                  required: true,
                })}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "right",
                  margin: "2% 0%",
                }}
              >
                {fields?.length - 1 === i && (
                  <Inputs.Button
                    text={"Добави нов"}
                    className="dotted"
                    onClick={() => {
                      append({
                        insuranceCompanyName: "",
                        insuranceCompanyId: "",
                        insuranceSum: null,
                        premium: null,
                        installmentOneSum: null,
                        installmentTwoSum: null,
                        installmentThreeSum: null,
                        installmentFourSum: null,
                        documents: "",
                        generalConditions: "",
                        additionalInfo: "",
                        fullDiscount: {
                          ...watch()?.offers?.[0]?.fullDiscount,
                          // discount: 0,
                        },
                      });
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        );
      })}

      <ModalComponent
        open={openModal}
        handleClose={() => {
          setOpenModal(false);
        }}
        children={
          <DiscountInfoModal
            discount={currentTicket?.promoData}
            type={watch()?.offers?.[0]?.fullDiscount?.type}
            handleClose={() => {
              setOpenModal(false);
            }}
          />
        }
      />
    </div>
  );
};
const mapStateToProps = (state) => ({
  agencies: state.offer.agencies,
  currentTicket: state.tickets.currentTicket,
});
const mapDispatchToProps = (dispatch) => ({
  listAgencies: (payload) => dispatch(listAgencies(payload)),
  createOffer: (payload) => dispatch(createOffer(payload)),
  getCurrentTicket: (payload) => dispatch(getCurrentTicket(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CreateOffer);
