/* eslint-disable */
import { supportTypes } from "../actions/support";

const initialState = {
  connected: false,
  tickets: [],
  messagesById: {},
};

const handleTicketMessages = (state, payload) => {
  const newState = { ...state };
  if (payload?.docs?.length) {
    const ticketId = payload.docs[0].ticket;

    if (payload.page === 1) {
      newState[ticketId] = payload;
    } else {
      const filtered = payload.docs.filter((message) => !newState[ticketId].docs.find((oldMessage) => oldMessage._id === message._id));
      newState[ticketId] = {
        ...payload,
        docs: [...state[ticketId].docs, ...filtered],
      };
    }
  }
  return newState;
};

const handleNewMessage = (state, payload) => {
  const newState = { ...state };
  const ticketId = payload.ticket._id;
  const message = { ...payload };
  message.ticket = ticketId;

  if (newState[ticketId]) {
    newState[ticketId].docs = [message, ...newState[ticketId].docs];
  }

  return newState;
};

const handlePaymentSuccess = (state, payload) => {
  // const _state = { ...state };
  const _state = JSON.parse(JSON.stringify(state));
  const ticketId = payload.ticket?._id;

  if (_state[ticketId]) {
    const index = _state[ticketId].docs.findIndex((message) => message._id === payload?._id);
    if (index === -1) return state;
    _state[ticketId].docs[index] = payload;
  }
  return _state;
};

const support = (state = initialState, { type, payload }) => {
  switch (type) {
    case "socket/updatePayment":
      return {
        messagesById: handlePaymentSuccess(state.messagesById, payload),
      };
    case supportTypes.SUPPORT_NEW_MESSAGE:
      return {
        ...state,
        messagesById: handleNewMessage(state.messagesById, payload),
      };
    case supportTypes.SUPPORT_TICKET_MESSAGES:
      return {
        ...state,
        messagesById: handleTicketMessages(state.messagesById, payload),
      };
    case supportTypes.SUPPORT_INIT:
      return {
        ...state,
        tickets: payload.tickets,
      };
    case supportTypes.SUPPORT_SOCKET_CONNECTED:
      return {
        ...state,
        connected: true,
      };
    case supportTypes.SUPPORT_SOCKET_DISCONNECT:
      return {
        ...state,
        connected: false,
      };
    default:
      return state;
  }
};

export default support;
