/* eslint-disable */
import { amarantFieldsTypes } from "../actions/amarantFields";

const initialState = {
  vehicles: [],
  policies: [],
  vignettes: [],
  errors: [],
  documents: [],
  totalErrors: 0,
  totalPages: 0,
  fines: [],
  user: {},
};

const handleNewDocs = (state, payload) => {
  if (payload.page === 1) return payload.errors;
  const _docs = payload.errors.filter((newDoc) => !state.errors.find((oldDoc) => newDoc._id === oldDoc._id));
  return [...state.errors, ..._docs];
};

const amarantFields = (state = initialState, { type, payload }) => {
  console.log(type, payload);
  switch (type) {
    case amarantFieldsTypes.LIST_VEHICLES_SUCCESS:
      return {
        ...state,
        vehicles: payload,
      };
    case amarantFieldsTypes.USER_INFO_SUCCESS:
      return {
        ...state,
        user: payload,
      };
    case amarantFieldsTypes.EDIT_USER_SUCCESS:
      return {
        ...state,
        user: payload,
      };
    case amarantFieldsTypes.LIST_POLICIES_SUCCESS:
      return {
        ...state,
        policies: payload,
      };
    case amarantFieldsTypes.LIST_VIGNETTES_SUCCESS:
      return {
        ...state,
        vignettes: payload,
      };
    case amarantFieldsTypes.LIST_FINES_SUCCESS:
      return {
        ...state,
        fines: payload,
      };
    case amarantFieldsTypes.LIST_DOCUMENTS_SUCCESS:
      return {
        ...state,
        documents: payload,
      };
    case amarantFieldsTypes.LIST_ERRORS_SUCCESS:
      return {
        ...state,
        errors: handleNewDocs(state, payload),
        totalErrors: payload.totalDocs,
        totalPages: payload.totalPages,
      };
    default:
      return state;
  }
};

export default amarantFields;
