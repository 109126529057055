/* eslint-disable */
import { format } from "date-fns";
import React, { useRef, useCallback, useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Ratings from "../ChatActions/Ratings/Ratings";
import { supportSocketEmit } from "../../actions/support";
import styles from "./ChatMessagesHistory.module.scss";
import Highlight from "../ChatActions/Search/Highlight";
import Timestamp from "../ChatActions/Timestamp/Timestamp";
import Swal from "sweetalert2";
import moment from "moment";

const payment_messages_to_exlude = ["651555104e3ce3152db7e2d3"]

function ChatMessagesHistory({ messages, supportSocketEmit, nextPage, searchTags, setMatches, hasNextPage }) {
  const navigate = useNavigate();
  const { topicId } = useParams();
  const observer = useRef(null);
  const [canceled, setCanceled] = useState(false);
  
  const handleLoadMessagesRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting === true && hasNextPage === true) {
          //nextPage < totalPages
          const event = {
            action: "nextMessages",
            payload: {
              ticket: topicId,
              pageSize: 20,
              pageNumber: nextPage,
            },
          };
          supportSocketEmit(event);
        }
      });
      if (node) observer.current.observe(node);
    },
    [nextPage]
  );

  return (
    <>
      {messages?.map((message, index) => (
        <div ref={handleLoadMessagesRef} className={`${styles.message} ${message?.type !== "Customer" ? styles.me : ""}`} key={message?._id}>
          {message?.variant == "payment" && (
            <>
              <p className={styles.time}>{format(new Date(message?.createdAt), "HH:mm")}</p>
              <div className={styles["message-body"]} style={{ textAlign: "end" }}>
                <Highlight setMatches={setMatches} tags={searchTags}>
                  <p>ИНИЦИИРАНО ПЛАЩАНЕ</p>
                  <p>
                    Сума <b>{(message?.metaData?.amount / 100).toFixed(2)} лв.</b>
                  </p>
                  <p>
                    Основание <b>{message?.text}</b>
                  </p>
                  <p>
                    Статус:{" "}
                    <b className={`${styles["status"]} ${message?.metaData?.status === "paid" || payment_messages_to_exlude.includes(message?._id) ? styles["green"] : null}`}>
                      {message?.paymentCanceled === true ? "Анулирано" : message?.metaData?.status === "paid" || payment_messages_to_exlude.includes(message?._id) ? "Платено" : "Неплатено"}
                    </b>
                  </p>
                </Highlight>
              </div>
              {message?.paymentCanceled === false && message?.metaData?.status !== "paid" && !payment_messages_to_exlude.includes(message?._id) ? (
                <div
                  className={styles["deletePayment"]}
                  onClick={() => {
                    supportSocketEmit({
                      action: "cancelPayment",
                      payload: {
                        message: message?._id,
                      },
                    });

                    const event = {
                      action: "nextMessages",
                      payload: {
                        ticket: topicId,
                        pageSize: 200,
                        pageNumber: nextPage - 1,
                      },
                    };
                    supportSocketEmit(event);
                    Swal.fire("Плащането беше анулирано успешно", "", "success");
                    setCanceled(true);
                  }}
                >
                  <div className={styles["bin"]} />
                  <b style={{ color: "#8244EB" }}>Анулирай плащане</b>
                </div>
              ) : null}
              {messages?.filter((mess) => mess?.type === "User" && mess?.variant === "payment").length && (message?.metaData?.status === "paid" || payment_messages_to_exlude.includes(message?._id)) ? (
                <div
                  className={styles["deletePayment"]}
                  onClick={() => {
                    navigate(
                      `/addPolicyToUser/${topicId}/${message?.metaData?.customerSupportPayment?.customerSupportId}/${
                        messages?.filter((mess) => mess?.variant === "offer" && mess?.offer?.accepted)?.length
                          ? messages?.filter((mess) => mess?.variant === "offer" && mess?.offer?.accepted)?.[0]?.offer?._id
                          : "no-offer"
                      }`
                    );
                  }}
                >
                  <div className={styles["add-policy"]} />
                  <b style={{ color: "#01D088" }}>Добави полица към профила</b>
                </div>
              ) : null}
            </>
          )}
          {message?.variant == "offer" && (
            <>
              {message?.type !== "Customer" && <p className={styles.time}>{format(new Date(message?.createdAt), "HH:mm")}</p>}

              <div className={styles["message-body"]} style={{ textAlign: message?.type !== "Customer" && "end" }}>
                <Highlight setMatches={setMatches} tags={searchTags}>
                  <div
                    style={{
                      margin: "5px 0px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: message?.type === "Customer" && "row-reverse",
                    }}
                  >
                    {message?.offer?.accepted ? (
                      <p style={{ color: "#01D088", fontSize: "12px" }}>
                        <b>ИЗБРАНА ОФЕРТА</b>
                      </p>
                    ) : (
                      <div
                        className={`${styles["edit-offer-icon"]}`}
                        onClick={() => {
                          navigate(`/editOffer/${topicId}/${message?.offer?._id}`);
                        }}
                      />
                    )}
                    <b style={{ textTransform: "uppercase" }}>{message?.offer?.insuranceCompanyName}</b>
                  </div>
                  <p style={{ margin: "5px 0px" }}>
                    Застрахователна сума: <b>{message?.offer?.insuranceSum} лв.</b>
                  </p>
                  {message?.offer?.originalPremium ? (
                    <>
                      <p style={{ margin: "5px 0px" }}>
                        Премия преди остъпка <b>{message?.offer?.originalPremium} лв.</b>
                      </p>
                      <p style={{ margin: "5px 0px" }}>
                        Отстъпка{" "}
                        <b>
                          {(message?.offer?.originalPremium - message?.offer?.premium).toFixed(2)} лв.{" "}
                          {`(${Math.round(100 - (message?.offer?.premium / message?.offer?.originalPremium) * 100)} %)`}
                        </b>
                      </p>
                      <p style={{ margin: "5px 0px" }}>
                        Премия след остъпка <b>{message?.offer?.premium} лв.</b>
                      </p>
                    </>
                  ) : (
                    <p style={{ margin: "5px 0px" }}>
                      Премия <b>{message?.offer?.premium} лв.</b>
                    </p>
                  )}

                  <p style={{ margin: "5px 0px" }}>
                    Вноска 1: <b>{message?.offer?.installmentOneSum?.toFixed(2) || "---"} лв.</b>
                  </p>
                  <p style={{ margin: "5px 0px" }}>
                    Вноска 2: <b>{message?.offer?.installmentTwoSum || "---"} лв.</b>
                  </p>
                  <p style={{ margin: "5px 0px" }}>
                    Вноска 3: <b>{message?.offer?.installmentThreeSum || "---"} лв.</b>
                  </p>
                  <p style={{ margin: "5px 0px" }}>
                    Вноска 4: <b>{message?.offer?.installmentFourSum || "---"} лв.</b>
                  </p>
                </Highlight>
              </div>
              {message?.type === "Customer" && <p className={styles.time}>{format(new Date(message?.createdAt), "HH:mm")}</p>}
            </>
          )}
          {message?.variant == "vote" && <Ratings message={message} />}

          {message?.variant !== "text" && message?.variant !== "vote" && message?.variant !== "payment" && message?.variant !== "offer" ? (
            <>
              {message?.type !== "Customer" && <p className={styles.time}>{format(new Date(message?.createdAt), "HH:mm")}</p>}
              {message?.variant === "photo" ? (
                <div
                  className={`${styles["photo-message"]}`}
                  style={{
                    backgroundImage: `url(${message?.text})`,
                    backgroundPosition: message?.type === "Customer" ? "left" : "right",
                    cursor: "pointer",
                  }}
                  onClick={() => window.open(message?.text)}
                />
              ) : (
                <a className={`${styles["message-body"]} ${styles.media}`} href={message?.text} download={message?.text} target="_blank" style={{ wordBreak: "break-word" }}>
                  {message?.text}
                </a>
              )}

              {message?.type === "Customer" && <p className={styles.time}>{format(new Date(message?.createdAt), "HH:mm")}</p>}
              <p className={`${styles["message-status"]} ${message?.readByCustomer === true && styles["green"]}`}>
                {index === 0 && message?.type.includes("User")
                  ? message?.readByCustomer === true
                    ? `Прочетено ${moment(message?.readByCustomerDate).format("HH:mm")}`
                    : "Доставено"
                  : null}
              </p>
            </>
          ) : (
            <>
              {message?.variant !== "vote" && message?.variant !== "payment" && message?.variant !== "offer" ? (
                <>
                  {message?.type !== "Customer" ? (
                    <>
                      <p className={styles.sender}>{message?.from?.name}</p>

                      <p className={styles.time}>{format(new Date(message?.createdAt), "HH:mm")}</p>
                      <div className={styles["message-body"]}>
                        <Highlight setMatches={setMatches} tags={searchTags}>
                          {message?.text}
                        </Highlight>
                      </div>
                      <p className={`${styles["message-status"]} ${message?.readByCustomer === true && styles["green"]}`}>
                        {index === 0 && message?.type.includes("User")
                          ? message?.readByCustomer === true
                            ? `Прочетено ${moment(message?.updatedAt).format("HH:mm")}`
                            : "Доставено"
                          : null}
                      </p>
                    </>
                  ) : (
                    <>
                      <p className={styles.sender}>{message?.from?.name}</p>
                      <div className={styles["message-body"]}>
                        <Highlight setMatches={setMatches} tags={searchTags}>
                          {message?.text}
                        </Highlight>
                      </div>
                      <p className={styles.time}>{format(new Date(message?.createdAt), "HH:mm")}</p>
                    </>
                  )}
                </>
              ) : null}
            </>
          )}
          {index == 0 ||
            (format(new Date(message?.createdAt), "dd.MM.yyyy") != format(new Date(messages[index - 1]?.createdAt), "dd.MM.yyyy") && (
              <Timestamp time={messages[index - 1]?.createdAt} />
            ))}
        </div>
      ))}
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  supportSocketEmit: (payload) => dispatch(supportSocketEmit(payload)),
});

export default connect(null, mapDispatchToProps)(ChatMessagesHistory);
