/* eslint-disable */
import { Box, Button, Divider, Popover, Typography } from "@mui/material";
import React, { useEffect, useState, useRef, useCallback } from "react";
import styles from "./AmarantComponent.module.scss";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { connect } from "react-redux";
import moment from "moment";
import CollectedDataModal from "./CollectedDataModal";
import RequestModal from "./RequestModal";
import Statistics from "../Statistics";

const columnNames = ["Дата", "Час", "Заглавие", "Обект", "CalculateOnly", "Room", "AllCollectedData", "Заявка", "Грешка"];

const fieldNames = ["createdAt", "createdAt", "platform", "requestData", "calculateOnly", "room"];

function ErrorsComponent({ errors, tableRef, totalPages, listErrors, customerID }) {
  const [filter, setFilter] = useState("");
  const [currentError, setCurrentError] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [nextPage, setNextPage] = useState(2);
  const [openCollectedData, setOpenCollectedData] = useState(false);
  const [requestModal, setRequestModal] = useState(false);
  const [innerLoading, setInnerLoading] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onScroll = () => {
    let e = tableRef.current;
    const bottom = e.scrollHeight - e.scrollTop === e.clientHeight || e.scrollHeight - e.scrollTop <= e.clientHeight + 1;

    if (bottom) {
      if (nextPage <= totalPages && !innerLoading) {
        setInnerLoading(true);
        listErrors({
          //customerSupportId: "6231bc7bc8696d0016d4af13",
          customerSupportId: customerID,
          pageNumber: nextPage,
          onSuccess: (res) => setInnerLoading(false),
        });
        setNextPage(nextPage + 1);
      }
    }
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <div className={styles["blurred-background"]} id="blurred">
        <div className={styles["errorModal"]}>
          <div className={styles["headerModal"]} style={{ width: "97%" }}>
            <b style={{ textTransform: "uppercase", fontSize: "16px", marginLeft: window.innerWidth < 900 && "4%" }}>ЗАЯВКА</b>
            <div
              className={styles["closeModal"]}
              onClick={() => {
                document.getElementById("blurred").style.display = "none";
              }}
            />
          </div>
          <div className={styles["bodyModal"]} style={{ border: "none" }}>
            <div className={styles["innerBox"]}>
              <p>{currentError?.error}</p>
            </div>
            <div
              className={styles["buttonContainer"]}
              style={{
                width: "95%",
                display: "flex",
                justifyContent: "center",
                marginTop: "4%",
              }}
            >
              <div
                className={styles["copyButton"]}
                onClick={(event) => {
                  navigator.clipboard.writeText(currentError?.error);
                  handleClick(event);
                }}
              >
                Копирай
              </div>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={() => {
                  handleClose();
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                sx={{
                  height: "150px",
                }}
              >
                <Typography sx={{ padding: "10px" }}>Копирано успешно.</Typography>
              </Popover>
            </div>
          </div>
        </div>
      </div>
      {window.innerWidth < 900 ? (
        <>
          <div className={styles["tabs-container"]} style={{ overflowY: "scroll", padding: "0px", height: "120%" }} ref={tableRef} onScroll={() => onScroll()}>
            {errors?.map((error, i) => {
              return (
                <div
                  className={styles["row-container"]}
                  style={{
                    marginTop: "5%",
                    display: "block",
                    height: "unset",
                  }}
                  key={i}
                >
                  {fieldNames.map((field, j) => {
                    return (
                      <React.Fragment key={j}>
                        <div className={styles["mobile-box"]}>
                          <p style={{ marginBottom: "1%", fontSize: "14px" }}>{columnNames[j]}</p>
                          <b style={{ marginBottom: "1%", fontSize: "14px" }}>
                            {j === 0
                              ? moment(error[field]).format("DD.MM.YYYY")
                              : j === 1
                              ? moment(error[field]).format("HH:mm")
                              : j === 3
                              ? error[field]?.licensePlate
                              : error[field] === true
                              ? "Да"
                              : error[field] === false
                              ? "Не"
                              : error[field]}
                          </b>
                        </div>
                        <Divider />
                      </React.Fragment>
                    );
                  })}
                  <div className={styles["mobile-box"]}>
                    <p style={{ marginBottom: "1%", fontSize: "14px" }}>AllCollectedData</p>
                    <b style={{ marginBottom: "1%", fontSize: "14px" }}>
                      <div
                        style={{
                          display: "flex",
                          width: "30%",
                          marginTop: "1%",
                        }}
                        onClick={() => {
                          setCurrentError(error);
                          setOpenCollectedData(true);
                        }}
                      >
                        <b
                          style={{
                            textDecoration: "underline",
                            color: "#0DC1CE",
                            cursor: "pointer",
                            fontSize: "15px",
                          }}
                        >
                          Преглед
                        </b>
                        <div className={styles["info-icon"]} style={{ marginLeft: "2%", marginRight: "5%" }} />
                      </div>
                    </b>
                  </div>
                  <Divider />
                  <div className={styles["mobile-box"]}>
                    <p style={{ marginBottom: "1%", fontSize: "14px" }}>Заявка</p>
                    <b style={{ marginBottom: "1%", fontSize: "14px" }}>
                      <div
                        style={{
                          display: "flex",
                          width: "30%",
                          marginTop: "1%",
                        }}
                        onClick={() => {
                          setCurrentError(error);
                          setRequestModal(true);
                        }}
                      >
                        <b
                          style={{
                            textDecoration: "underline",
                            color: "#0DC1CE",
                            cursor: "pointer",
                            fontSize: "15px",
                          }}
                        >
                          Преглед
                        </b>
                        <div className={styles["info-icon"]} style={{ marginLeft: "2%", marginRight: "5%" }} />
                      </div>
                    </b>
                  </div>
                  <Divider />
                  <div className={styles["mobile-box"]}>
                    <p style={{ marginBottom: "1%", fontSize: "14px" }}>Грешка</p>
                    <b style={{ marginBottom: "1%", fontSize: "14px", display: "flex", alignItems: "center" }}>
                      <div
                        className={styles["errorLog"]}
                        onClick={() => {
                          setCurrentError(error);
                          document.getElementById("blurred").style.display = "block";
                        }}
                      />
                      {error?.screenshot && (
                        <p
                          style={{
                            color: "#0DC1CE",
                            textDecoration: "underline",
                            cursor: "pointer",
                            marginLeft: "5%",
                          }}
                          onClick={() => {
                            window.open(error?.screenshot);
                          }}
                        >
                          Скрийншот
                        </p>
                      )}
                    </b>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      ) : (
        <>
          <div className={styles["columns-container"]}>
            <div className={styles["upper-names"]}>
              {columnNames.map((column, i) => {
                return (
                  <div className={`${styles["name-icon"]}`} style={{ width: i === 2 ? "11%" : "8.2%" }} key={i}>
                    <p>{column}</p>
                    <KeyboardArrowDownIcon
                      style={{
                        transform: filter === "plate" && "rotate(180deg)",
                        cursor: "pointer",
                        display: i > 3 && "none",
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className={styles["tabs-container"]} style={{ overflowY: "scroll" }} ref={tableRef} onScroll={() => onScroll()}>
            {errors?.map((error, i) => {
              return (
                <div className={styles["row-container"]} key={i}>
                  <div className={styles["data"]}>
                    <b>{moment(error.createdAt).format("DD.MM.YYYY")}</b>
                  </div>
                  <Divider
                    style={{
                      backgroundColor: "#D3D3D3",
                      height: "65%",
                    }}
                    orientation="vertical"
                  />
                  <div className={styles["data"]}>
                    <b>{moment(error.createdAt).format("HH:mm")}</b>
                  </div>
                  <Divider
                    style={{
                      backgroundColor: "#D3D3D3",
                      height: "65%",
                    }}
                    orientation="vertical"
                  />
                  <div className={styles["data"]} style={{ width: "11%" }}>
                    <b>Error in {error.platform}</b>
                  </div>
                  <Divider
                    style={{
                      backgroundColor: "#D3D3D3",
                      height: "65%",
                    }}
                    orientation="vertical"
                  />
                  <div
                    className={styles["data"]}
                    style={{
                      justifyContent: "space-between",
                      display: "flex",
                    }}
                  >
                    <b>{error.requestData.licensePlate}</b>
                    {/*  <div
                  className={styles["errorLog"]}
                  onClick={() => {
                    setCurrentError(error);
                    document.getElementById("blurred").style.display = "block";
                  }}
                />
                */}
                  </div>
                  <Divider
                    style={{
                      backgroundColor: "#D3D3D3",
                      height: "65%",
                    }}
                    orientation="vertical"
                  />
                  <div className={styles["data"]} style={{ width: "7%" }}>
                    <b>{`${error?.calculateOnly}`}</b>
                  </div>
                  <Divider
                    style={{
                      backgroundColor: "#D3D3D3",
                      height: "65%",
                    }}
                    orientation="vertical"
                  />
                  <div className={styles["data"]} style={{ width: "10%" }}>
                    <b style={{ wordBreak: "break-word" }}>{`${error?.room}`}</b>
                  </div>
                  <Divider
                    style={{
                      backgroundColor: "#D3D3D3",
                      height: "65%",
                    }}
                    orientation="vertical"
                  />
                  <div className={styles["data"]} style={{ width: "8%" }}>
                    <p>Детайли:</p>
                    <div
                      className={styles["info-icon"]}
                      onClick={() => {
                        setCurrentError(error);
                        setOpenCollectedData(true);
                      }}
                    />
                  </div>
                  <Divider
                    style={{
                      backgroundColor: "#D3D3D3",
                      height: "65%",
                    }}
                    orientation="vertical"
                  />
                  <div className={styles["data"]} style={{ width: "8%" }}>
                    <p>Детайли:</p>
                    <div
                      className={styles["info-icon"]}
                      onClick={() => {
                        setCurrentError(error);
                        setRequestModal(true);
                      }}
                    />
                  </div>
                  <Divider
                    style={{
                      backgroundColor: "#D3D3D3",
                      height: "65%",
                    }}
                    orientation="vertical"
                  />

                  <div className={styles["data"]} style={{ width: "8%" }}>
                    <div
                      className={styles["errorLog"]}
                      onClick={() => {
                        setCurrentError(error);
                        document.getElementById("blurred").style.display = "block";
                      }}
                    />
                    {error?.screenshot && (
                      <p
                        style={{
                          color: "#0DC1CE",
                          textDecoration: "underline",
                          cursor: "pointer",
                          marginLeft: "5%",
                        }}
                        onClick={() => {
                          window.open(error?.screenshot);
                        }}
                      >
                        Скрийншот
                      </p>
                    )}
                  </div>
                </div>
              );
            })}
            {innerLoading && (
            <div className="inner-loader-container" style={{ display: "flex", justifyContent: "center", width: "100%" }}>
              <Statistics.LoaderInline center />
            </div>
          )}
          </div>
          
        </>
      )}
      <CollectedDataModal open={openCollectedData} handleClose={() => setOpenCollectedData(false)} currentError={currentError} mobileModal={window.innerWidth < 900 && true} />
      <RequestModal open={requestModal} handleClose={() => setRequestModal(false)} data={currentError} mobileModal={window.innerWidth < 900 && true} fromError={true} />
    </>
  );
}

const mapStateToProps = (state) => ({
  fines: state.amarantFields.fines,
});

const mapDispatchToProps = (dispatch) => ({
  listFines: (payload) => dispatch(listFines(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorsComponent);
